import React, { useState } from "react";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";


const schema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    taxId: {
      type: "string",
    },
    owner: {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        isOrganization: {
          type: "boolean",
        },
      },
    },
    address: {
      type: "object",
      properties: {
        country: {
          type: "string",
        },
        stateOrProvince: {
          type: "string",
        },
        city: {
          type: "string",
        },
        street: {
          type: "string",
        },
        postalCode: {
          type: "integer",
        },
      },
    },
    contacts: {
      type: "object",
      properties: {
        phone: {
          type: "string",
        },
        email: {
          type: "string",
        },
        website: {
          type: "string",
        },
      },
    },
  },
  required: ["name", "taxId", "owner"],
};

const uischema = {
  type: "Categorization",
  elements: [
    {
      type: "Category",
      label: "Basics",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "Hotel Name",
              type: "Control",
              scope: "#/properties/name",
            },
            {
              label: "Tax ID",
              type: "Control",
              scope: "#/properties/taxId",
            },
          ],
        },
        {
          type: "VerticalLayout",
          elements: [
            {
              label: "Hotel Owner",
              type: "Control",
              scope: "#/properties/owner/properties/name",
            },
            {
              label: "The owner is an organozation",
              type: "Control",
              scope: "#/properties/owner/properties/isOrganization",
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "Address",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "Country",
              type: "Control",
              scope: "#/properties/address/properties/country",
            },
            {
              label: "State or Province",
              type: "Control",
              scope: "#/properties/address/properties/stateOrProvince",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "City",
              type: "Control",
              scope: "#/properties/address/properties/city",
            },
            {
              label: "Postal Code",
              type: "Control",
              scope: "#/properties/address/properties/postalCode",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "Street",
              type: "Control",
              scope: "#/properties/address/properties/street",
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "Rooms",
      elements: [
        {
          type: "Control",
          scope: "#/properties/name",
        },
      ],
    },
    {
      type: "Category",
      label: "Contacts",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "Phone number",
              type: "Control",
              scope: "#/properties/contacts/properties/phone",
            },
            {
              label: "Email",
              type: "Control",
              scope: "#/properties/contacts/properties/email",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              label: "Website",
              type: "Control",
              scope: "#/properties/contacts/properties/website",
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "Review",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/name",
              options: {
                readonly: true,
              },
            },
          ],
        },
      ],
    },
  ],
};

const roomTypesList = [];
const tabsList = ["basics", "address", "rooms", "contacts", "review"];
export const HotelRegistrationForm = (props) => {
  const [roomTypes, setRoomTypes] = useState(roomTypesList);
  const [roomCount, setRoomCount] = useState(0);
  const [roomType, setRoomType] = useState("");


  const [hotelRegistrationData, setHotelRegistrationData] = useState();

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes.write
  });

  const handleAddTask = () => {

    execute("POST", protectedResources.apiTodoList.endpointHotel + `/PostHotels`, hotelRegistrationData).then((response) => {
        if (response && response.message === "success") {
          setHotelRegistrationData();
        }
    })
  };


  function handleRoomCountChange(event) {
    setRoomCount(event.target.value);
  }
  function handleRoomTypeChange(event) {
    setRoomType(event.target.value);
  }

  function handleAdd() {
    const newList = roomTypes.concat({
      id: uuidv4(),
      count: roomCount,
      type: roomType,
    });

    setRoomTypes(newList);
  }

  return (
    <>
      <JsonForms
        schema={schema}
        uischema={uischema}
        data={hotelRegistrationData}
        renderers={materialRenderers}
        cells={materialCells}
        onChange={({ data, _errors }) => setHotelRegistrationData(data)}
      />
      <Button variant="primary" size="sm" onClick={handleAddTask}>
        Review + Register
      </Button>
    </>
  );
};

// "name": "Liberty Hotel",
// "taxId": "123465444355",
// "chain": null,
// "address": {
//     "street": "Sommerstrasse 31",
//     "city": "Munich",
//     "stateOrProvice": "Bayern",
//     "postalCode": "81543",
//     "country": "Germany"
// },
// "contact": {
//     "phone": "+492334534513",
//     "email": "libertyhotel@mail.com",
//     "website": "www.libertyhotel.com"
// },
// "owner": {
//     "name": "Joseph Owren",
//     "isOrganization": "false"
// },
// "managementCompany": null,
// "establishedIn": 0,
// "rooms": {
//     "total": 5,
//     "roomTypes": [
//         {
//             "count": 5,
//             "type": "Single"
//         }
//     ]
// },
// "classification": 5,
// "affilication": null,
// "operatingHours": {
//     "checkIn": "15:00",
//     "checkOut": "11:00",
//     "frontDesk": "7:00-23:00"
// },
// "emergencyContact": null,
